<ion-app>
  <ion-split-pane [when]="'(min-width: 768px)'" contentId="main-content">
    <ion-menu
      menuId="main"
      contentId="main-content"
      [side]="side"
      [hidden]="url.includes('/u/guide/tutorial/')"
      [swipeGesture]="false"
      [disabled]="!splitPaneService.isVisible"
    >
      <ion-header>
        <ion-toolbar color="primary" class="shadow-none px-3.5 mt-2 h-auto">
          <div class="flex items-center">
            <img src="assets/icon/ic_logo_white.svg" class="block md:hidden" />
            <img src="assets/icon/img_logo-small_white.svg" class="hidden md:block" tappable [routerLink]="['/']" />
            @if (!environment.production) {
              <img src="../assets/matraz.svg" alt="Dev" title="Dev" class="pl-2 mt-1 md:mt-2" />
            }
          </div>
          <ion-menu-toggle slot="end" tappable>
            <ion-icon size="large" name="close-outline" class="p-0 m-0"></ion-icon>
          </ion-menu-toggle>
        </ion-toolbar>
        <div color="primary" class="shadow-none ml-[18px] mr-3">
          <div class="md:pt-[38px] min-h-[135px]">
            <app-user-info-card [color]="'primary'"></app-user-info-card>
          </div>
        </div>
        <hr class="bg-white mb-[30px] mt-0 md:my-[38px]" />
      </ion-header>
      <ion-content>
        @let user = appStore.user();
        @let userSessionStatus = appStore.user()?.status;
        <div class="flex flex-col h-full">
          <div class="px-0 flex-grow">
            <ion-list lines="none" class="text-md flex flex-col md:gap-[2px] p-0">
              <h3 class="text-white pl-3.5 pr-4 pb-1 md:pb-2 md:px-4 m-0 font-semibold">Menú</h3>
              @if (appStore.siteTreeMenu(); as siteMenu) {
                <ion-menu-toggle class="hidden md:block">
                  <ion-item
                    routerLink="u/home"
                    #menu="routerLinkActive"
                    routerLinkActive="selected"
                    routerDirection="root"
                    detail="false"
                    color="primary"
                  >
                    <monto-icon
                      aria-hidden="true"
                      [size]="'full'"
                      class="max-w-[22px]"
                      slot="start"
                      color="light"
                      [name]="menu.isActive ? 'home-filled' : 'home'"
                    ></monto-icon>
                    <ion-label>Inicio</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                @if (
                  siteMenu.profile?.show &&
                  ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                ) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/user-profile"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <ion-icon
                        slot="start"
                        [src]="
                          menu.isActive
                            ? 'assets/icon/ic_profile_white_fill.svg'
                            : 'assets/icon/ic_profile_white_outline.svg'
                        "
                      ></ion-icon>
                      <ion-label>{{ siteMenu.profile?.label || 'Mi Perfil' }}</ion-label>
                      @if (siteMenu.profile?.badge) {
                        <ion-badge color="light">{{ siteMenu.profile?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (
                  siteMenu.payment_service?.show &&
                  ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                ) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/payment-services"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[18px]"
                        slot="start"
                        color="light"
                        [name]="menu.isActive ? 'payment-service-filled' : 'payment-service'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.payment_service?.label || 'Pago de servicios' }}</ion-label>
                      @if (siteMenu.payment_service?.badge) {
                        <ion-badge color="light">{{ siteMenu.payment_service?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (
                  siteMenu.topups?.show &&
                  ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                ) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/top-ups"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[20px]"
                        slot="start"
                        color="light"
                        [name]="menu.isActive ? 'topups-filled' : 'topups'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.topups?.label || 'Recargas' }}</ion-label>
                      @if (siteMenu.topups?.badge) {
                        <ion-badge color="light">{{ siteMenu.topups?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (
                  siteMenu.benefits?.show &&
                  ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                ) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/benefits"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[21px]"
                        slot="start"
                        color="light"
                        [name]="menu.isActive ? 'diamond-filled' : 'diamond'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.benefits?.label || 'Descuentos' }}</ion-label>
                      @if (siteMenu.benefits?.badge) {
                        <ion-badge color="light">{{ siteMenu.benefits?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (
                  siteMenu.gift_cards?.show &&
                  (services$ | ngrxPush)?.[EServicesAvailableStatus.BILL_PAYMENTS_STP] !== true &&
                  ![UserStatus.PENDING_VERIFY_PHONE, UserStatus.PENDING_VALIDATION].includes($any(userSessionStatus))
                ) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/gift-card"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[19px]"
                        slot="start"
                        color="light"
                        [name]="menu.isActive ? 'gift-filled' : 'gift'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.gift_cards?.label || 'Tarjetas de regalo' }}</ion-label>
                      @if (siteMenu.gift_cards?.badge) {
                        <ion-badge color="light">{{ siteMenu.gift_cards?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (siteMenu.shop?.show && user?.hasCompany) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/store"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[19px]"
                        slot="start"
                        color="light"
                        [name]="menu.isActive ? 'shop-filled' : 'shop'"
                      ></monto-icon>
                      <ion-label class="flex gap-2">
                        {{ siteMenu.shop?.label || 'Tienda Monto' }}
                        @if (siteMenu.shop?.badge) {
                          <ion-badge color="light">{{ siteMenu.shop?.badge }}</ion-badge>
                        }
                      </ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (siteMenu.health?.show || user?.profile?.hasMontoHealth) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="/u/1doc3"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[19px]"
                        slot="start"
                        [color]="'light'"
                        [name]="menu.isActive ? 'medic-filled' : 'medic'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.health?.label || 'Salud' }}</ion-label>
                      @if (siteMenu.health?.badge) {
                        <ion-badge color="light">{{ siteMenu.health?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (appStore.user()?.hasCompany && servicesStore.insurancesIsActive()) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="//u/insurance/list"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[19px]"
                        slot="start"
                        [color]="'light'"
                        [name]="menu.isActive ? 'insurance-filled' : 'insurance'"
                      ></monto-icon>
                      <ion-label>{{ servicesStore.insurancesConfig()?.label || 'Seguros' }}</ion-label>
                      @if (servicesStore.insurancesConfig()?.badge) {
                        <ion-badge color="light">{{ servicesStore.insurancesConfig()?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (siteMenu.history?.show) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      routerLink="u/records"
                      #menu="routerLinkActive"
                      routerLinkActive="selected"
                      routerDirection="root"
                      detail="false"
                      color="primary"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[19px]"
                        slot="start"
                        [color]="'light'"
                        [name]="menu.isActive ? 'clock-filled' : 'clock'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.history?.label || 'Historial' }}</ion-label>
                      @if (siteMenu.history?.badge) {
                        <ion-badge color="light">{{ siteMenu.history?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (siteMenu.redeem?.show && user?.hasCompany) {
                  <ion-menu-toggle class="block">
                    <ion-item tappable="" detail="false" color="primary" (click)="onOpenModalRedeem()">
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[26px]"
                        slot="start"
                        [color]="'light'"
                        [name]="'trophy'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.redeem?.label || 'Redimir premio' }}</ion-label>
                      @if (siteMenu.redeem?.badge) {
                        <ion-badge color="light">{{ siteMenu.redeem?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
                @if (siteMenu.refer?.show) {
                  <ng-template [ngIf]="userSessionStatus === UserStatus.ACTIVE" [ngIfElse]="recommendOption">
                    <ion-menu-toggle class="block">
                      <ion-item
                        lines="none"
                        color="primary"
                        routerDirection="root"
                        [routerLink]="['/u/refer']"
                        routerLinkActive="selected"
                        #menu="routerLinkActive"
                        [queryParams]="{ goBack: 'true' }"
                      >
                        <monto-icon
                          aria-hidden="true"
                          [size]="'full'"
                          class="max-w-[22px]"
                          slot="start"
                          color="light"
                          [name]="menu.isActive ? 'thumbs-up-filled' : 'thumbs-up'"
                        ></monto-icon>
                        <ion-label>{{ siteMenu.refer?.label || 'Recomienda Monto' }}</ion-label>
                        @if (siteMenu.refer?.badge) {
                          <ion-badge color="light">{{ siteMenu.refer?.badge }}</ion-badge>
                        }
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-template>

                  <ng-template #recommendOption>
                    <ion-menu-toggle class="block">
                      <ion-item
                        lines="none"
                        color="primary"
                        routerDirection="root"
                        [routerLink]="['/unauth/recommend-company-inside']"
                        routerLinkActive="selected"
                        #menu="routerLinkActive"
                        [queryParams]="{ goBack: 'true' }"
                      >
                        <monto-icon
                          aria-hidden="true"
                          [size]="'full'"
                          class="max-w-[22px]"
                          slot="start"
                          color="light"
                          [name]="menu.isActive ? 'thumbs-up-filled' : 'thumbs-up'"
                        ></monto-icon>
                        <ion-label>{{ siteMenu.refer?.label || 'Recomienda Monto' }}</ion-label>
                        @if (siteMenu.refer?.badge) {
                          <ion-badge color="light">{{ siteMenu.refer?.badge }}</ion-badge>
                        }
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-template>
                }
                @if (siteMenu.about?.show) {
                  <ion-menu-toggle class="block">
                    <ion-item
                      lines="none"
                      color="primary"
                      tappable
                      [routerLink]="['/u/about']"
                      routerLinkActive="selected"
                      #menu="routerLinkActive"
                      [queryParams]="{ goBack: 'true' }"
                    >
                      <monto-icon
                        aria-hidden="true"
                        [size]="'full'"
                        class="max-w-[22px]"
                        slot="start"
                        color="light"
                        [name]="menu.isActive ? 'monto-filled' : 'monto'"
                      ></monto-icon>
                      <ion-label>{{ siteMenu.about?.label || 'Contáctanos' }}</ion-label>
                      @if (siteMenu.about?.badge) {
                        <ion-badge color="light">{{ siteMenu.about?.badge }}</ion-badge>
                      }
                    </ion-item>
                  </ion-menu-toggle>
                }
              }
            </ion-list>
          </div>
          <ion-menu-toggle class="block mt-auto pb-5">
            <ion-item lines="none" color="primary" lines="none" tappable (click)="logout()" class="pt-2">
              <ion-icon class="text-xl mr-3 my-1.5 md:my-0" slot="start" name="log-out-outline"></ion-icon>
              <ion-label>Cerrar sesión</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
