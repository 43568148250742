import { IUser, UserStatus } from '@modules/user/interfaces/user'
import { AccountInfoModel } from '@modules/user/models/account-info.model'
import { ProfileModel } from '@modules/user/models/profile.model'
import { Profile } from '@modules/user/models/profile'
import { IAccountInfo } from '@modules/user/interfaces/account-info'
import { IProfile } from '@modules/user/interfaces/profile'
import { Primitives } from 'src/app/shared/interfaces/Primitives'

interface IUserModel extends IUser {
  account_info?: IAccountInfo | AccountInfoModel | null
  profile: IProfile | ProfileModel | null
}

export class UserModel {
  readonly #user!: IUserModel

  constructor(user: IUserModel) {
    this.#user = user
    if (user.profile) {
      this.#user.profile = new ProfileModel(new Profile(<IProfile>(<unknown>user.profile)))
    }
    if (user.account_info) {
      this.#user.account_info = new AccountInfoModel(<IAccountInfo>(<unknown>user.account_info))
    }
  }

  setAccountInfo(accountInfo: AccountInfoModel | null) {
    this.#user.account_info = accountInfo
  }

  get id(): string {
    return this.#user.id
  }

  get getName(): string {
    const splitName = this.#user.first_name?.split(' ')
    if (splitName?.length > 1) {
      return splitName[0]
    }
    return this.#user.first_name
  }

  get firstName(): string {
    return this.#user.first_name
  }

  get lastName(): string {
    return this.#user.last_name
  }

  get rfc(): string {
    return this.profile.rfc
  }

  get birthDate(): string {
    return this.profile.birthDate
  }

  get hasCompany(): boolean {
    return !!this.profile?.companyId
  }

  get email(): string {
    return this.#user.email
  }

  get isActive(): boolean {
    return this.#user.is_active
  }

  get createdAt(): string {
    return this.#user.created_at
  }

  get updatedAt(): string {
    return this.#user.updated_at
  }

  get isFirstTimeLogin(): boolean {
    return this.#user.is_first_time_login
  }

  get addSignUpDate(): string {
    return this.#user.add_sign_up_date
  }

  get status(): UserStatus {
    return this.#user.status
  }

  get identifier(): string {
    return this.#user.identifier
  }

  get accountInfo(): AccountInfoModel | null {
    return this.#user?.account_info as AccountInfoModel
  }

  get profile(): ProfileModel | null {
    return this.#user?.profile as ProfileModel
  }

  get services() {
    return this.#user?.profile?.services
  }

  toJSON(): Primitives<UserModel> {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      isActive: this.isActive,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      isFirstTimeLogin: this.isFirstTimeLogin,
      addSignUpDate: this.addSignUpDate,
      status: this.status,
      identifier: this.identifier,
      accountInfo: <AccountInfoModel>this.accountInfo?.toJSON(),
      profile: <ProfileModel>this.profile?.toJSON(),
      getName: this.getName,
      hasCompany: this.hasCompany,
      services: this.services,
      rfc: this.rfc,
      birthDate: this.birthDate,
    }
  }

  toPrimitive(): Primitives<IUserModel> {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      is_active: this.isActive,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      is_first_time_login: this.isFirstTimeLogin,
      add_sign_up_date: this.addSignUpDate,
      status: this.status,
      identifier: this.identifier,
      ...((this.accountInfo ? { account_info: this.accountInfo?.toJSON() } : {}) as AccountInfoModel),
      profile: <ProfileModel>this.profile?.toJSON(),
      profile_picture: null,
    }
  }
}
