import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { combineLatest, exhaustMap, of, tap } from 'rxjs'
import {
  deleteAccount,
  profileLoadRemote,
  removeSession,
  userLoading,
  userLoadRemote,
  userPutBalance,
  userSession,
} from '../actions/user.actions'
import { SplitPaneService } from '@services/split-pane.service'
import { SessionService } from '@services/session.service'
import { BiometricService } from '@modules/biometric/services/biometric.service'
import { Store } from '@ngrx/store'
import { IUser, UserStatus } from '@modules/user/interfaces/user'
import { UserResource } from '@modules/user/resources/user.resource'
import { catchError, debounceTime, filter, finalize, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { selectUser, selectUserLoadLoading } from '@modules/user/selectors/user.selectors'
import { UserModel } from '@modules/user/models/user.model'
import { IProfile } from '@modules/user/interfaces/profile'
import { IAccountInfo } from '@modules/user/interfaces/account-info'
import { IUserResponse } from '@modules/user/interfaces/user-response'
import { IProfileResponse } from '@modules/user/interfaces/profile-response'
import { ProfileModel } from '@modules/user/models/profile.model'
import { Profile } from '@modules/user/models/profile'
import { AppStore } from '@modules/app/app.store'
import { ServiceStore } from '@modules/app/services.store'

export type TUserLoadRemoteResponse = [IUserResponse | null, IProfileResponse | null]

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  readonly appStore = inject(AppStore)
  readonly servicesStore = inject(ServiceStore)
  removeSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeSession),
        tap(async () => {
          await this._sessionService.destroySession()
          localStorage.clear()
          sessionStorage.clear()
          this._splitPaneService.setPaneStatus(false)
          this._splitPaneService.hidePane()
        })
      ),
    { dispatch: false }
  )

  deleteAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteAccount),
        tap(() => {
          this._biometricService.setBiometricActivate(false)
          this._biometricService.deleteCredentials()
          this._store.dispatch(removeSession())
        })
      ),
    { dispatch: false }
  )

  userLoadRemote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLoadRemote),
        withLatestFrom(this._store.select(selectUserLoadLoading), this._store.select(selectUser)),
        filter(([action, isLoading]) => {
          return !isLoading
        }),
        tap(([action, loading, currentUser]) => {
          this._store.dispatch(
            userLoading({
              loading: true,
            })
          )
          const user = this._userRersource.get().pipe(catchError(() => of(null)))
          const profile = this._userRersource.profile().pipe(catchError(() => of(null)))
          combineLatest([user, profile])
            .pipe(
              switchMap(([user, profile]) => {
                return of([user, profile] as TUserLoadRemoteResponse)
              })
            )
            .subscribe(([user, profile]: TUserLoadRemoteResponse) => {
              if (!user) {
                this._store.dispatch(
                  userLoading({
                    loading: false,
                  })
                )
                return
              }
              const userModel = new UserModel({
                ...user.user,
                profile: profile?.profile as IProfile,
                account_info: profile?.profile?.account_info as IAccountInfo,
              })

              if (
                userModel.hasCompany &&
                [UserStatus.ACTIVE, UserStatus.CHANGED_PASSWORD].includes(userModel.status) &&
                userModel?.profile?.activeStatus === UserStatus.ACTIVE
              ) {
                if (!this.appStore.paramsCredits()) {
                  this.appStore.loadParamsCredits().subscribe()
                }
                if (
                  !this.appStore.creditInfo() &&
                  (profile.profile.is_credit_active || this.appStore.siteTreeHome()?.shop?.show)
                ) {
                  this.appStore.loadCreditInfo().subscribe()
                }
              }
              if (userModel.hasCompany) {
                this.servicesStore.getServiceTree().subscribe()
              }
              const userSerializer = JSON.parse(JSON.stringify(userModel))
              this._store.dispatch(
                userPutBalance({
                  balance: undefined,
                  balanceFuture: undefined,
                })
              )
              this.appStore.putUser(userSerializer)
              this._store.dispatch(
                userSession({
                  user: userSerializer,
                })
              )
              this._store.dispatch(
                userLoading({
                  loading: false,
                })
              )
            })
        })
      ),
    { dispatch: false }
  )

  profileLoadRemote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileLoadRemote),
      debounceTime(300),
      withLatestFrom(this._store.select(selectUserLoadLoading), this._store.select(selectUser)),
      filter(([action, isLoading]) => {
        return !isLoading
      }),
      exhaustMap(([action, isLoading, user]) => {
        this._store.dispatch(
          userLoading({
            loading: true,
          })
        )
        this._store.dispatch(
          userPutBalance({
            balance: undefined,
            balanceFuture: undefined,
          })
        )
        return this._userRersource.profile().pipe(
          finalize(() => {
            this._store.dispatch(
              userLoading({
                loading: false,
              })
            )
          }),
          map(profile => {
            const userSerializer = {
              ...user,
              profile: new ProfileModel(new Profile(<IProfile>(<unknown>profile.profile))).toJSON() as ProfileModel,
            } as UserModel
            this.appStore.putUser(userSerializer)
            return userSession({
              user: userSerializer,
            })
          })
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private _splitPaneService: SplitPaneService,
    private _store: Store<{ user: IUser }>,
    private _biometricService: BiometricService,
    private _sessionService: SessionService,
    private _userRersource: UserResource
  ) {}
}
